<template>
	<div id="accessibility">
		<nav
			id="accessibilitynav"
			:class="{open: accessibilityOpen}"
			aria-label="Menü zur Barrierefreiheit"
		>
			<ul>
				<li>
					<a
						class="text-white focus:text-white hover:text-white"
						href="#help"
						accesskey="1"
						@keyup.tab.stop.prevent="accessibilityItemKeyup($event)"
						@focusout.stop.prevent="accessibilityItemFocusout($event)"
						@click.stop.prevent="accessibilityItemClicked($event)"
					>
						Bedienhilfe
					</a>

					<!-- Help -->
					<div
						id="accessibilityhelp"
						class="container bg-primary-800 text-white/80 [&_a]:text-white/80 [&_a:hover]:text-white/100 [&_a:focus]:text-white/100 [&_p]:text-white"
					>
						<div class="flex flex-wrap">
							<div class="w-full md:w-1/2 px-6 py-6">
								<ul>
									<li>
										[key] + 1:
										<span class="font-bold">Bedienhilfe</span>
									</li>
									<li>
										[key] + 2:
										<span class="font-bold">direkt zur Suche</span>
									</li>
									<li>
										[key] + 3:
										<span class="font-bold">direkt zum Menü</span>
									</li>
									<li>
										[key] + 4:
										<span class="font-bold">direkt zum Inhalt</span>
									</li>
									<li>
										[key] + 5:
										<span class="font-bold">Seitenanfang</span>
									</li>
									<li>
										[key] + 6:
										<span class="font-bold">Kontaktinformationen</span>
									</li>
									<li>
										[key] + 7:
										<span class="font-bold">Kontaktformular</span>
									</li>
									<li>
										[key] + 8:
										<span class="font-bold">Startseite</span>
									</li>
								</ul>
								<p>
									<span class="font-bold">[key]</span>
									steht für die Tastenkombination, welche Sie drücken müssen, um
									auf die
									<span class="font-bold">accesskeys</span>
									Ihres Browsers Zugriff zu erhalten.
								</p>
								<p>
									Windows:
									<br />
									Chrome, Safari, IE:
									<span class="font-bold">[alt]</span>
									, Firefox:
									<span class="font-bold">[alt][shift]</span>
								</p>
								<p>
									MacOS:
									<br />
									Chrome, Firefox, Safari:
									<span class="font-bold">[ctrl][alt]</span>
								</p>
							</div>
							<div class="w-full md:w-1/2 px-6 py-6">
								<div class="flex flex-wrap -mx-6 -my-6">
									<div class="w-full md:w-1/2 px-6 py-6">
										<p>
											Zwischen den Links können Sie mit der
											<span class="font-bold">Tab</span>
											-Taste wechseln.
										</p>
										<p>
											Karteireiter, Tabmenüs und Galerien in der Randspalte
											wechseln Sie mit den
											<span class="font-bold">Pfeil</span>
											-Tasten.
										</p>
									</div>
									<div class="w-full md:w-1/2 px-6 py-6">
										<p>
											Einen Link aufrufen können Sie über die
											<span class="font-bold">Enter/Return</span>
											-Taste.
										</p>
										<p>
											Denn Zoomfaktor der Seite können Sie über
											<span class="font-bold">Strg +/-</span>
											einstellen.
										</p>
									</div>
									<nav
										class="px-6 py-6 w-full"
										aria-label="Weitere Links zum Thema Barrierefreiheit"
									>
										<hr />
										<ul class="flex-column">
											<li v-if="linkBarrierefreiheit">
												<a
													:href="linkBarrierefreiheit"
													@keyup.tab.stop.prevent="
														accessibilityItemKeyup($event)
													"
												>
													Erklärung zur Barrierefreiheit
												</a>
											</li>
											<li v-if="linkLeichteSprache">
												<a
													:href="linkLeichteSprache"
													@keyup.tab.stop.prevent="
														accessibilityItemKeyup($event)
													"
												>
													Unsere Website in leichter Sprache
												</a>
											</li>
											<li v-if="linkSeitenbedienung">
												<a
													:href="linkSeitenbedienung"
													@keyup.tab.stop.prevent="
														accessibilityItemKeyup($event)
													"
												>
													Seitenbedienung
												</a>
											</li>
											<li v-if="linkFehlerMelden">
												<a
													:href="linkFehlerMelden"
													@keyup.tab.stop.prevent="
														accessibilityItemKeyup($event)
													"
												>
													Fehler melden
												</a>
											</li>
										</ul>
									</nav>
								</div>
							</div>
						</div>
					</div>
				</li>
				<li>
					<a
						href="#gotosearch"
						accesskey="2"
						class="text-white focus:text-white hover:text-white"
						@keyup.tab.stop.prevent="accessibilityItemKeyup($event)"
						@click.stop.prevent="accessibilityItemClicked($event)"
					>
						direkt zur Suche
					</a>
				</li>
				<li>
					<a
						href="#nav"
						class="text-white focus:text-white hover:text-white"
						accesskey="3"
						@keyup.tab.stop.prevent="accessibilityItemKeyup($event)"
						@click.stop.prevent="accessibilityItemClicked($event)"
					>
						direkt zum Menü
					</a>
				</li>
				<li>
					<a
						href="#content"
						class="text-white focus:text-white hover:text-white"
						accesskey="4"
						@keyup.tab.stop.prevent="accessibilityItemKeyup($event)"
						@click.stop.prevent="accessibilityItemClicked($event)"
					>
						direkt zum Inhalt
					</a>
				</li>
				<li>
					<a
						id="top"
						href="#top"
						class="text-white focus:text-white hover:text-white"
						accesskey="5"
						@keyup.tab.stop.prevent="accessibilityItemKeyup($event)"
						@click.stop.prevent="accessibilityItemClicked($event)"
					>
						Seitenanfang
					</a>
				</li>
				<li v-if="linkKontaktinformationen">
					<a
						:href="linkKontaktinformationen"
						class="text-white focus:text-white hover:text-white"
						accesskey="6"
						@keyup.tab.stop.prevent="accessibilityItemKeyup($event)"
					>
						Kontaktinformationen
					</a>
				</li>
				<li v-if="linkKontaktformular">
					<a
						:href="linkKontaktformular"
						class="text-white focus:text-white hover:text-white"
						accesskey="7"
						@keyup.tab.stop.prevent="accessibilityItemKeyup($event)"
					>
						Kontaktformular
					</a>
				</li>
				<li>
					<a
						:href="linkStartseite"
						class="text-white focus:text-white hover:text-white"
						accesskey="8"
						@keyup.tab.stop.prevent="accessibilityItemKeyup($event)"
						@focusout.stop.prevent="accessibilityItemFocusout($event)"
					>
						Startseite
					</a>
				</li>
			</ul>
		</nav>

		<!-- Overlay -->
		<div
			class="fixed inset-0 transition-opacity z-[999]"
			:class="{visible: accessibilityOpen, invisible: !accessibilityOpen}"
			aria-hidden="true"
			@click="accessibilityOpen = false"
		/>
	</div>
</template>

<script>
import {useEmitter} from '#nk/composables/emitter';
import {jumpTo} from '#imports';

export default {
	name: 'NkTemplateAccessibility',

	props: {
		linkBarrierefreiheit: {
			type: String,
			required: false,
			default: null,
		},
		linkLeichteSprache: {
			type: String,
			required: false,
			default: null,
		},
		linkSeitenbedienung: {
			type: String,
			required: false,
			default: null,
		},
		linkFehlerMelden: {
			type: String,
			required: false,
			default: null,
		},
		linkKontaktinformationen: {
			type: String,
			required: false,
			default: null,
		},
		linkKontaktformular: {
			type: String,
			required: false,
			default: null,
		},
		linkStartseite: {
			type: String,
			required: false,
			default: '/',
		},
	},

	data() {
		return {
			accessibilityOpen: false,
		};
	},

	methods: {
		accessibilityItemKeyup(e, close = false) {
			document.body.classList.add('kb-nav-used');

			this.$el.querySelectorAll('#accessibilitynav li').forEach(element => {
				element.classList.remove('focused');
			});

			if (!close) {
				if (e.target.closest('#accessibilityhelp')) {
					e.target
						.closest('#accessibilityhelp')
						.parentNode.classList.add('focused');
				} else {
					e.target.parentNode.classList.add('focused');
				}
			}

			this.accessibilityOpen = !close;
		},

		accessibilityItemFocusout(e) {
			if (!e.relatedTarget || !e.relatedTarget.closest('#accessibilitynav')) {
				this.accessibilityItemKeyup(e, true);
			}
		},

		accessibilityItemClicked(e) {
			const emitter = useEmitter();
			// Bedienhilfe schließen
			if (e.target.attributes.href.value === '#help') {
				this.accessibilityItemKeyup(e, this.accessibilityOpen);
			}

			// Zur Suche
			if (e.target.attributes.href.value === '#gotosearch') {
				this.accessibilityItemKeyup(e, true);
				emitter.emit('nk:search-open');
			}

			// Zum Menü
			if (e.target.attributes.href.value === '#nav') {
				this.accessibilityItemKeyup(e, true);

				emitter.emit('nk:menu-open');
			}

			// Zum Inhalt
			if (e.target.attributes.href.value === '#content') {
				this.accessibilityItemKeyup(e, true);
				const focusable =
					'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';

				if (
					document.querySelector('.nk-carousel + nav + *') &&
					document.querySelector('.nk-carousel + nav + *').length
				) {
					window.setTimeout(() => {
						document
							.querySelector('.nk-carousel + nav + *')
							.querySelector(focusable)
							.focus();
					}, 250);
				} else if (document.querySelector('[data-a11y-content]') != null) {
					window.setTimeout(() => {
						document
							.querySelector('[data-a11y-content]')
							.querySelector(focusable)
							.focus();
					}, 250);
				} else {
					window.setTimeout(() => {
						document
							.querySelector('.nk-carousel + *')
							.querySelector(focusable)
							.focus();
					}, 250);
				}
			}

			// Top
			if (e.target.attributes.href.value === '#top') {
				this.accessibilityItemKeyup(e, true);
				jumpTo('#accessibility');
			}
		},
	},
};
</script>

<style lang="scss">
#accessibilitynav {
	position: fixed;
	top: -999em;
	left: 50%;
	width: 100%;
	height: 3rem;
	color: white;
	transform: translateX(-50%);

	&::before {
		@apply fixed;
		@apply content-[''];
		@apply inset-0;
		@apply min-h-[100dvh];
		@apply bg-neutral-900/75;
		@apply -z-10;
	}

	&.open {
		top: 0;
		visibility: visible;
		z-index: 999999;
	}

	> ul {
		position: relative;
		margin: 1rem 0;
		padding-left: 0;
		list-style: none;

		> li {
			position: absolute;
			left: 0;
			width: 100%;
			height: 0;
			text-align: center;
			opacity: 0;
			z-index: 0;

			&.focused {
				height: auto;
				opacity: 1;
				z-index: 999;
			}
		}
	}
}

#accessibilityhelp {
	position: fixed;
	z-index: 99999;
	top: -999em;
	left: 50%;
	max-height: calc(100vh - 4rem);
	overflow-y: auto;
	font-size: 0.9rem;
	text-align: left;
	transform: translateX(-50%);

	ul {
		margin-bottom: 1rem;
		padding-left: 0;
	}

	li {
		display: block;
		padding: 0;

		&::after {
			display: none;
		}

		a {
			text-decoration: underline;
		}
	}

	hr {
		margin-top: 1rem;
		@apply border-white;
	}
}

.focused #accessibilityhelp {
	top: 3rem;
	z-index: 999998;
}
</style>
